.blog-layout {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 10px;
  padding: 25px;
  margin: 0 auto;
  a {
    text-decoration: none;
  }
  a:hover {
    transition: all 0.5s ease-in-out;
    transform: scale(1.02);
  }
  .blog-post {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    min-height: 300px;
    box-sizing: border-box;
    border: 2px solid #000;
    border-radius: 5px;
    .upper,
    .lower {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
    .upper {
      height: 40%;
      width: 100%;
      background-color: #eeeded;
      .blog-title {
        font-family: 'PoppinsRegular';
        font-size: 25px;
        font-weight: bolder;
        text-align: center;
        color: #608dd2;
      }
    }
    .lower {
      height: 60%;
      width: 100%;
      padding: 25px;
      box-sizing: border-box;
      color: #608dd2;
      display: flex;
      flex-direction: column;
      align-items: center;
      .blog-author {
        font-family: 'Simplifica';
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 2px;
        color: rgba(61, 47, 230, 1);
      }
      .blog-preview {
        font-family: 'PoppinsRegular';
        font-size: 16px;
        color: #333333;
      }
    }
  }
}

@media (max-width: 550px) {
  .blog-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 25px;
    margin: 0;
  }
}
