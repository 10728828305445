.toggle-button {
  height: 24px;
  width: 36px;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-around;

  .bar-line {
    background-color: black;
    height: 2px;
    width: 35px;
  }
}

.toggle-button:focus {
  outline: none;
}
