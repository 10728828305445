@font-face {
  font-family: Abril;
  src: url('./fonts/AbrilFatface-Regular.ttf');
}
@font-face {
  font-family: Simplifica;
  src: url('./fonts/Simplifica.ttf');
}
@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: MontserratRegular;
  src: url('./fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: Raleway;
  src: url('./fonts/Raleway-Regular.ttf');
}
@font-face {
  font-family: SourceSansPro;
  src: url("./fonts/SourceSansPro-Bold.ttf");
}
.App {
  display: flex;
  flex-direction: column;  
  min-height: 100vh;
}
.App .spacer{
  flex-grow: 1;
  z-index: -100;
}
