.post-container{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .blog-editor{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 16px;
    box-sizing: border-box;
    .unordered-list{
      padding: 0;
      .editor{
        height: 100%;
        width: 100%;
        padding: 5px;
        .toolbar-class{
          background-color: rgba(47, 107, 197, 0.77);
        }
        //draft-js text-box
        div.DraftEditor-root {
          border: 1px solid #000;
          background-color: white;
          height: 60vh;
          overflow-y: auto;
        }
        div.rdw-option-active{
          border: 2px solid #000;
          border-radius: 2px;
        }
        div.DraftEditor-editorContainer,
        div.public-DraftEditor-content {
          height: 100%;
        }
      }
      .list-options{
        list-style:none;
        text-align: center;
        margin: 5px;
        padding: 5px;
        input, textarea, select{
          width: 85vw;
          height: auto;
          font-family: 'Montserrat';
          font-size: 16px;
          padding: 5px;
          -ms-box-sizing:content-box;
          -moz-box-sizing:content-box;
          -webkit-box-sizing:content-box;
          box-sizing:content-box;
        }
        textArea{
          height: 90vh;
          overflow: auto;
        }
      }
    }
    .buttons{
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100px;
      .save, .cancel{
        border: 2px solid #000;
        border-radius: 5px;
        padding: 5px;
        margin: 10px 10px 35px 10px;
        cursor: pointer;
        box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
        -o-box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
        -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
      }
      .save:hover, .cancel:hover{
        color:#3d2fe6;
      }
      .save:active, .cancel:active{
        background-color: white;
        color: black;
        transform: translate3d(0,1px,-5px);
      }
    }
    .preview{
      min-width: 100%;
      h1{
        text-align: center;
        color: #333;
        opacity: 0.7;
      }
      .post{
        border: 2px solid rgba(47, 107, 197, 0.77);
      }
      margin-bottom: 20px;
    }
  }
}