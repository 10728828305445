@font-face {
  font-family: Abril;
  src: url('../../fonts/AbrilFatface-Regular.ttf');
}
@font-face {
  font-family: Simplifica;
  src: url('../../fonts/Simplifica.ttf');
}
@font-face {
  font-family: Montserrat;
  src: url('../../fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: Raleway;
  src: url('../../fonts/Raleway-Regular.ttf');
}
.footer {
  position: relative;
  box-sizing: border-box;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding: 5px;
  background-color: rgba(47,107,197,0.77);
  color: #fff;
  font-weight: bolder;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  font-family: Simplifica;
  @media (max-width: 900px) {
    letter-spacing: 0px;
  }
  .copyright {
    padding: 10px;
  }
  .spacer {
    flex: 1;
  }
  .logo-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    .media-links {
      padding: 10px;
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
