.contact-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  .contact-form{
    width:50%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;
    .contact-info{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      .location, .email{
        width: 50%;
        display: flex;
        margin-top: 20px;
        justify-content: flex-start;
        align-items: center;
        span{
          margin: 0 10px;
        }
      }
    }
  }
  .faq-container{
    width: 50%;
    min-width: 300px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: MontSerratRegular, serif;
    .q-and-a{
      width: 100%;
      box-sizing: border-box;
      background: #F2EFEF;
      border: 1px solid rgba(0,0,0,0.5);
      padding: 10px;
      margin: 5px;
    }
  }
  @media (max-width: 600px){
    .contact-form, .faq-container{
      width: 100%;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
