@font-face {
  font-family: PoppinsRegular;
  src: url('../../../fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: Abril;
  src: url('../../../fonts/AbrilFatface-Regular.ttf');
}
.navbar-container {
  width: 100%;
  height: 100px;
  font-family: PoppinsRegular;
  background-color: rgba(47,107,197,0.77);
  .navbar-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    .spacer {
      flex: 1;
    }
    .logo {
      width: 275px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo-back{
        width: 100px;
        height: 100px;
        background-image: url('../../../images/logo.PNG');  
        background-repeat: no-repeat;
        background-size: contain;
      }
      .logo-text{
        width: 175px;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: bolder;
        font-size: 18px;
      }
      .logo-text-link{
        text-decoration: none;
        color: #ffffff;
      }
      .logo-text-link:hover{
        cursor: pointer;
      }
    }
    .menu-links {
      @media (max-width: 900px) {
        display: none;
      }
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 800px;
      height: 100%;
      //menu texts
      span {
        padding: 5px;
        font-weight: bold;
        a {
          text-decoration: none;
        }
        a:hover {
          color: white !important;
          cursor: pointer;
        }
      }
      //JOIN US button
      span:last-child{
        background-color: #DFB877;
        border-radius: 7px;
        padding: 7px;
      }
    }

    .hamburger {
      box-sizing: border-box;
      @media (min-width: 900px) {
        display: none;
      }
    }
  }
}
