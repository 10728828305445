.event-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  min-height: 300px;
  height: auto;
  box-sizing: border-box;
  margin: 30px auto;
  color: #333;
  border: rgba(51, 51, 51, 0.2) 1px solid;
  border-radius: 5px;
  box-shadow: 8px -3px 7px 2px rgba(51, 51, 51, 0.5);
  background-color: rgba(248,248,248,1);
  .left {
    width: 30%;
    height: 100%;
    align-self: center;
    .image-container{
      height: 300px;
      width: 100%;
      .event-image{
        height: 100%;
        width: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

  }
  .right {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    .event-name {
      font-size: 25px;
      padding: 5px;
      height: 20%;
      font-family: MontSerrat, serif;
      color: rgba(96,141,210,1);
    }
    .location {
      font-size: 14px;
      padding: 5px;
      font-family: PoppinsRegular, serif;
      display: flex;
      color: rgba(96,141,210,1);
    }
    .date {
      font-size: 14px;
      padding: 5px;
      height: 10%;
      font-family: PoppinsRegular, serif;
      display: flex;
      color: rgba(96,141,210,1);
    }
    .description {
      font-size: 14px;
      padding: 5px;
      height: auto;
      font-family: PoppinsRegular, serif;
      display: flex;
      color: rgba(96,141,210,1);
    }
    .location, .date{
      .icon{
        height: 16px;
        width: 16px;
        margin: 5px;
      }
    }
    .register-button {
      font-size: 18px;
      font-family: Raleway, serif;
      margin: 25px 5px 5px;
      font-weight: bolder;
      a{
        background-color: #DFB877;
        border-radius: 7px;
        padding: 10px;
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .event-container {
    flex-direction: column;
    .left {
      width: 100%;
      height: 30%;
      .image-container{
        height: 300px;
        max-width: 100%;
        .event-image{
          height: 100%;
          width: 100%;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 0;
          border-top-right-radius: 5px;
        }
      }
    }
    .right {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: 30%;
      box-sizing: border-box;
      .event-name,
      .location,
      .date {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: unset;
      }
      .register-button {
        margin: 15px;
      }
    }
  }
}
