.home-container {
  width: 100%;
  //full viewport height minus the navbar 100px and footer 50px
  height: calc(100vh - 150px);
  position: absolute;
  top: 100px;
  left: 0;
  background-color: black;
  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    .current{
      background: rgba(47, 107, 197, 0.99) radial-gradient(circle at center, #fff 0%, #fff 35%, rgba(47, 107, 197, 0.5) 36%, rgba(47, 107, 197, 0.99) 37%, rgba(47, 107, 197, 0.99) 100%);
    }
    .slider {
      width: 100%;
      height: 100%;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      text-align: center;
      margin: 0 auto;
      -webkit-overflow-scrolling: touch;
      .slides {
        height: 100%;
        display: flex;
        overflow-x: auto;
        scroll-snap-align: start;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        div {
          scroll-snap-align: start;
          flex-shrink: 0;
          width: 100%;
          height: auto;
          margin-right: 50px;
          background: #eee;
          transform-origin: center center;
          transform: scale(1);
          transition: transform 0.5s;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 100px;
          z-index: 1;
          bottom: 0;
          background-color: #E5E5E5;
        }
        #slide-1,#slide-2,#slide-3{
          position: relative;
        }
        #slide-1:before{
          background-image: url('../../images/home/first.jpg');
          z-index: -1;
          content: '';
          position: absolute;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          background-color: transparent;
          background-attachment: fixed;
          width: 100%;
          height: 100%;
          opacity: 0.5;
        }
        #slide-2:before{
          background-image: url('../../images/home/second.jpg');
          z-index: -1;
          content: '';
          position: absolute;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          background-color: transparent;
          background-attachment: fixed;
          width: 100%;
          height: 100%;
          opacity: 0.5;
        }
        #slide-3:before{
          background-image: url('../../images/home/third.png');
          z-index: -1;
          content: '';
          position: absolute;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          background-color: transparent;
          background-attachment: fixed;
          width: 100%;
          height: 100%;
          opacity: 0.5;
        }
        .cover-text{
          width: 25%;
          margin-left: 20px;
          position: absolute;
          left: 0;
          opacity: 1;
          z-index: +5;
          font-size: calc(35px + 2vw);
          text-align: start;
          color: rgba(76,201,240,1);
          font-weight: bolder;
          letter-spacing: 2px;
          text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
        }
      }
      .page-circles{
        position: absolute;
        bottom: 0;
        margin-bottom: 70px;
        z-index: 3;
        left: 0;
        right: 0;
        a {
          line-height: 0;
          font-size: 0;
          color: transparent;
          text-decoration: none;
          display: inline-flex;
          width: 1.5rem;
          height: 1.5rem;
          background-color: rgba(47,107,197,0.77);
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin: 10px 0.5rem 0.5rem 0;
          //position: ;
          z-index: 99;
          pointer-events: all
        }
        a:hover {
          cursor: pointer;
          background-color: rgba(47,107,197,0.99);
          transform: scale(1.1);
        }
      }
      /* Hide scrollbar for Chrome, Safari and Opera */
      .slides::-webkit-scrollbar {
        display: none;
      }
      .slides{
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }
  }
}
