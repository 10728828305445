.about-page {
  max-width: 1500px;
  margin: 0 auto;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "SourceSansPro", serif;
    font-size: 30px;
    color: #608DD2;
    padding: 10px 30px;
    text-shadow:0 4px 4px rgba(0,0,0,0.3);
  }
  .spacer{
    flex-grow: 1;
  }
  .team {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SourceSansPro", serif;
    font-size: 30px;
    color: #608DD2;
    padding: 10px 30px;
    margin:  15px 15px;
  }
}
