.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1500px;
  .header {
    padding: 10px 25px;
    align-self: flex-start;
    font-family: MontSerrat;
  }
  .image-and-text-container {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px 25px;
    box-sizing: border-box;

    .image-container {
      width: 375px;
      height: 275px;
      box-sizing: border-box;
      padding: 5px;
      margin: 0 15px 0 0;
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    }

    .text-container {
      box-sizing: border-box;
      width: calc(100% - 375px);
      font-family: Raleway;
      text-align: justify;
    }
  }
  .reverse {
    flex-direction: row-reverse;
    .text-container {
      margin-right: 15px;
      margin-left: 0;
    }
  }
  .hide {
    .image-container{
      display: none;
      margin-top: 0;
    }
    .text-container{
      width: 100%;
    }
  }
}
//for small screen devices align image at top and text at its bottom
@media (max-width: 768px) {
  .container {
    .image-and-text-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .image-container {
        width: 90%;
      }
      .text-container {
        width: 100%;
        margin-left: 0;
        margin-top: 25px;
      }
    }
    .reverse {
      flex-direction: column;
    }
    .hide {
      .image-container{
        display: none;
      }
      .text-container{
        width: 100%;
        margin-top: 0;
      }
    }
  }
}
