.projects-page {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "SourceSansPro", serif;
    font-size: 30px;
    color: #608DD2;
    padding: 10px 30px;
    text-shadow:0 4px 4px rgba(0,0,0,0.3);
  }

}
