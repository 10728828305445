.card-container {
  width: 150px;
  padding: 5px;
  margin: 25px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  .image-container {
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    background-color: transparent;

    img{
      border-radius: 50%;
      box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    }
  }
  .text-container {
    text-align: center;
    pre{
      font-family: PoppinsRegular;
      font-size: 12px;
    }
  }
}
