.blog-page {
  max-width: 1500px;
  margin: 0 auto;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'SourceSansPro', serif;
    font-size: 30px;
    color: #608dd2;
    padding: 10px 30px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  }
  .spacer {
    flex-grow: 1;
  }
  .submission {
    font-size: 18px;
    padding: 10px 30px;
    font-style: italic;
    span {
      text-decoration: underline;
      font-weight: bolder;
    }
  }
  .guidelines {
    font-size: 18px;
    color: #333;
    padding: 10px 30px;
    font-style: italic;
    span {
      text-decoration: underline;
      font-weight: bolder;
    }
    .guidelines-content {
      font-size: 18px;
      padding: 10px 30px;
      font-style: normal;
      span {
        text-decoration: underline;
        font-weight: bolder;
      }
    }
  }
}
