.journal-page {
  max-width: 1500px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 20px;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'SourceSansPro', serif;
    font-size: 30px;
    color: #608dd2;
    padding: 10px 30px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    text-shadow: -1px 0 #333, 0 1px #333, 1px 0 #333, 0 -1px #333;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'PoppinsRegular', serif;
    font-size: 20px;
    text-align: center;
    color: #333;
    width: 100%;
    margin: 15px auto;
    box-sizing: border-box;
    padding: 15px 100px;
    .empty {
      flex-grow: 1;
    }
    a {
      text-decoration: none;
      color: #fff;
      .button {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 200px;
        margin: 5px;
        background-color: #fb8500;
        border-radius: 15px;
        padding: 15px;
        color: #fff;
        font-weight: bold;
        text-align: center;
      }
      .button:hover {
        cursor: pointer;
      }
    }
  }
  .spacer {
    flex-grow: 1;
  }
  .team {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SourceSansPro", serif;
    font-size: 30px;
    color: #608DD2;
    padding: 10px 30px;
    margin:  15px 15px;
  }
}
@media (max-width: 700px) {
  .journal-page {
    .title {
      padding: 15px 30px;
    }
    .description {
      padding: 50px 10px;
      font-size: 16px;
      margin-bottom: 50px;
      .empty {
        flex-grow: 1;
      }
    }
  }
}
