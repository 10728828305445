.post-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .intro {
    color: #333333;
    padding: 10px 0 10px 0;
    width: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    .post-title {
      font-family: Abril;
      text-align: center;
      letter-spacing: 0.1vw;
      font-size: calc(25px + 2vw);
      color: rgba(47, 107, 197, 0.77);
      text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
      max-width: 100%;
      word-break: break-word;
    }
    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .writer-info {
        font-family: Simplifica;
        font-weight: bolder;
        font-size: 20px;
        color: #000000;
        .author-tag {
          margin-right: 5px;
        }
        .date-tag {
          margin-left: 5px;
        }
      }
      .info-tag {
        background-color: rgba(47, 107, 197, 1);
        color: #ffffff;
        padding: 5px;
        border: 1px solid #000000;
        border-radius: 5px;
      }
    }
  }
  .image-container {
    height: 300px;
    width: 100%;
    background-color: #dfb877;
    margin: 15px auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    padding: unset;
  }
  .preview {
    width: 90%;
  }
  .post-body {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Raleway, serif;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    padding: 40px;
    font-weight: normal;
    box-sizing: border-box;
    .body-para {
      width: 60%;
    }
  }
  .post-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    .gallery-img-container {
      width: 450px;
      height: 450px;
      box-sizing: border-box;
      max-width: 100%;
      margin: 5px 5px 0;
      text-align: center;
      font-size: 12px;
    }
    .blog-gallery-images {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
@media (max-width: 550px) {
  .post-container {
    .post-body {
      padding: 10px;
      .body-para {
        font-size: 18px;
        width: 100%;
      }
    }
    .post-gallery {
      max-width: 90%;
      .gallery-img-container {
        width: 100%;
        height: auto;
      }
    }
  }
}
