.events-page {
  max-width: 1500px;
  margin: 0 auto;
  box-sizing: border-box;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "SourceSansPro", serif;
    font-size: 30px;
    color: #608DD2;
    max-width: 90%;
    margin: 30px auto;
    padding: 10px 30px;
    text-shadow:0 4px 4px rgba(0,0,0,0.3);
  }
}
