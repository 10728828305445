.sidebar {
  height: 100%;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(47,107,197,1);
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;

  @media (min-width: 769px) {
    display: none;
  }

  ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.2rem;

    li {
      margin: 0.5rem 0;
      a {
        text-decoration:none;
      }      
      a:hover{
        color: white !important; 
      }

    }
  }
}
.visible {
  transform: translateX(0);
}
