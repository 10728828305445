.blog-page {
  max-width: 1500px;
  margin: 0 auto;
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "SourceSansPro", serif;
      font-size: 30px;
      color: #608DD2;
      padding: 10px 30px;
      text-shadow:0 4px 4px rgba(0,0,0,0.3);
    }
    .filter-bar{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 15px;
      font-family: 'PoppinsRegular';
      div{
        margin: 2px;
        input{
          font-family: 'PoppinsRegular';
        }
      }

      .filter-button{
        border: 2px solid #000;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
        box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
   -o-box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
   -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
   -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 1px rgba(255,255,255,0.3), inset 0 10px rgba(255,255,255,0.2), inset 0 10px 20px rgba(255,255,255,0.25), inset 0 -15px 30px rgba(0,0,0,0.3);
      }
      .filter-button:hover{
        color:#3d2fe6;
      }
      .filter-button:active{
        background-color: white;
        color: black;
        transform: translate3d(0,1px,-5px);
      }
      
    }
    .spacer{
      flex-grow: 1;
    }
    .submission{
      font-size: 15px;
      padding: 10px 30px;
      font-style: italic;
      span{
        text-decoration: underline;
        font-weight: bolder;
      }
    }
  }
  