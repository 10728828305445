.project-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px auto;
    min-height: 500px;
    box-sizing: border-box;
    border: 2px solid rgba(255,255,255,0.4);
    box-shadow: 1px 4px 5px 1px rgba(51, 51, 51, 0.5);       
    .buttonsContainer{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 125px;
        width: 100%;
        box-sizing: border-box;
        .project-button{
            width: 100%;
            height: 125px;
            border: 1px solid rgba(255,255,255,0.4);
            border-bottom: 2px solid rgba(255,255,255,0.4);
            box-sizing: border-box;
        }
        .project-button > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #608DD2;
            font-size: 20px;
            font-weight: bold;
            word-break: break-word;
        }
    }
    //.flex
    .project-content{
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        padding: 15px;
        box-sizing: border-box;
        background-color: rgba(248,248,248,1);
    }
    //.flex-child
    .project-content > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: Raleway, serif;
        .space{
            flex: 1;
        }
        .link-button-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
            #project-link-button{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 200px;
                margin: 5px;
                background-color : rgba(251,133,0,1);
                border-radius: 15px;
                padding: 15px;
                color: #fff;
                font-weight: bold;
                text-align: center;
            }
            @media (max-width: 700px) {
                #project-link-button{
                    width: unset;
                }
            }
            a{
                text-decoration: none;
            }
        }
    }
}