.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px inset rgba(00, 00, 00, 0.1);
  border-radius: 5px;
  height: auto;
  width: 300px;
  padding: 15px;
  box-shadow: -5px -5px 5px 0px #124983;
  background-color: #608DD2;
  font-family: Simplifica;
  font-size: larger;
  font-weight: bold;
  letter-spacing: 2px;
  h2 {
    margin-bottom: 30px;
  }
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      margin: 5px;
    }
    input {
      height: 25px;
    }
    input,
    .comments {
      background-color: rgba(00, 00, 00, 0.5);
      color: #fff;
      width: 75%;
    }
    .comments {
      height: 100px;
      overflow: auto;
    }
    .button {
      max-width: 50%;
      margin: 5px auto;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 10px;
      border: 1px inset rgba(00, 00, 00, 0.8);
      border-radius: 5px;
      background: rgba(0, 0, 0, 0);
      height: 50px;
      font-family: Simplifica;
      font-size: larger;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .button:hover {
      cursor: pointer;
    }
  }
}
