.homepage {
  width: 100%;
  max-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;

  .title {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding: 0 15px;
    align-items: center;
    font-family: Abril;
    font-size: 25px;
  }
}
